.notification-lnk a {
    position: relative;
}

.notification-lnk i {
    font-size: 20px;
    color: var(--color-gray66);
}

.notify-cnt {
    position: absolute;
    top: -7px;
    right: -5px;
    background: var(--color-primary-yellow);
    font-size: 11px !important;
    width: 15px;
    height: 15px;
    text-align: center;
    line-height: 15px;
    border-radius: 100%;
    margin-left: 0;
    padding: 0;
    animation: notify-blur 1s infinite;
    color: var(--color-primary-maroon);
    display: flex;
    align-items: center;
    place-content: center
}

.header-block {
    height: var(--main-header-height);
    background: var(--header-bg);
    border-bottom: 1px solid var(--common-border-color);
}

.header-nav {
    position: fixed;
    top: 0;
    z-index: 99;
    width: 100%;
    background: #fff;
}

.header-nav .nav {
    grid-gap: 22px;
    margin-top: 0 !important;
}

.header-nav .nav a {
    color: var(--color-gray99);
    font-size: var(--font-size-13);
    font-weight: var(--font-weight-medium);
    border-radius: var(--common-border-radius);
    padding: 7px 10px !important;
}

.header-nav .nav a:hover,
.header-nav .nav a.active {
    background: var(--color-light-red);
    color: var(--common-maroon-color) !important;
}

.user-dropdown .dropdown-toggle::after {
    display: none
}

.user-dropdown i {
    color: var(--color-white);
}

.user-dropdown button {
    background: none;
    border: 0
}

.header-fixed .user-dropdown i {
    color: var(--theme-base-color)
}

.company-logo {
    min-height: var(--header-height);
    min-width: var(--left-panel-width);
    place-content: center;
}

.company-logo a {
    min-width: 190px;
}

.after-login-page .right-search-block .card {
    border: 0px !important;
    padding: 0px !important;
}

.profile-navigation.right-search-block ul li {
    margin: 5px 0;
}

.profile-navigation.right-search-block ul li button,
.profile-navigation.right-search-block ul li a {
    width: 100%;
    height: 50px;
    line-height: 50px;
    border-bottom: 1px solid var(--common-border-color) !important;
    display: flex;
    grid-gap: 0 15px;
    align-items: center;
    padding: 0;
    font-size: var(--font-size-16);
}

.profile-navigation.right-search-block ul li:last-child button,
.profile-navigation.right-search-block ul li:last-child a {
    border: 0 !important
}

.profile-navigation.right-search-block ul li:hover button,
.profile-navigation.right-search-block ul li button:hover,
.profile-navigation.right-search-block ul li button.active,
.profile-navigation.right-search-block ul li:hover a,
.profile-navigation.right-search-block ul li a:hover {
    background: none;
    color: var(--theme-secondary-color) !important;
}

.profile-navigation.right-search-block ul li .active svg path {
    fill: var(--secondary-green-color)
}

.profile-navigation svg {
    width: 18px;
}
.profile-navigation li:hover svg path,
.profile-navigation li a:hover svg path {
    fill: var(--secondary-green-color)
}

.footer-action {
    display: flex;
    justify-content: space-between;
    padding: 15px 5px 5px;
    border-radius: 4px;
    width: 100%;
    align-items: center;
    margin-top: 25px;
    border-top: 1px solid #e1e1e1;
}

.footer-action h3 {
    margin: 0px;
    padding: 0px;
    height: 30px;
    line-height: 30px;
    font-size: var(--font-size-16);
    color: var(--color-gray44);
    font-weight: var(--font-weight-normal);
}

.footer-action h3 span {
    color: var(--theme-secondary-color);
    font-weight: var(--font-weight-semibold);
}

/* ant select css added (March 22, 2024) ---START*/
.form-row .form-input .ant-select .ant-select-selector {
    width: 242px;
    height: 46px;
    padding: 0 12px;
}

.ant-select {
    font-family: inherit;
}

.ant-select-single .ant-select-selector {
    border-radius: 10px;
}

.ant-select .ant-select-arrow {
    font-size: 8px;
    inset-inline-end: -24px;
    top: 75%;
    color: #212529;
}

.ant-select .ant-select-clear {
    inset-inline-end: -24px;
    top: 75%;
}

.ant-select-dropdown {
    padding: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option {
    height: 45px !important;
    width: 99% !important;
    align-items: center;
    padding: 0 10px;
    border-bottom: 1px solid var(--common-input-border);
    border-radius: 0;
    color: var(--theme-base-color);
    transition: none;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-content {
    padding: 10px;
}

.ant-select-dropdown .ant-select-item.ant-select-item-option-content .ant-row .ant-col {
    font-size: var(--font-size-13) !important;
    font-weight: var(--font-weight-normal);
}

.ant-select-dropdown .ant-select-item.ant-select-item-option:hover {
    background-color: #0E3D81;
    color: white;
}

.ant-select-dropdown div .rc-virtual-list .rc-virtual-list-scrollbar .rc-virtual-list-scrollbar-thumb {
    right: 0;
    border-radius: 0 !important;
    z-index: 1000;
}

.ant-select-dropdown div .rc-virtual-list .rc-virtual-list-scrollbar {
    width: 5px !important;
}

.court-of-practice-drop-down .court-of-practice-select-wrapper {height: 46px;}
.match-per-val {display: none;}
/* ant select css added (March 22, 2024) ---END*/