
.ReactModal__Overlay{background: rgba(0,0,0,0.3) !important;}

.ReactModal__Content{padding:0px !important; box-shadow: 0px 3px 9px 0px rgb(0 0 0 / 6%);    border: 0px !important; border-radius: 20px !important;}
.ReactModal__Content--after-open {left: 25%; right: 25%;top: 8%; transform: inherit;}
.ReactModal-Header{height: var(--formview-topstrip-height) !important;  line-height: var(--formview-topstrip-height);   background: var(--modal-head-bg) !important;
    border-bottom: 1px solid var(--common-border-color);}
.ReactModal-Header h1{font-size: var(--font-size-26); padding: 0 20px;  margin: 0px; background: transparent;   width: 100%; color: var(--theme-secondary-dark-bg) !important;  height:80px; display: flex; align-items: center;  }
.Reactmodal-CloseBtn{height: var(--common-button-height);  border-radius: var(--common-input-border-radius);  background: var(--color-primary-maroon); display: grid;
    align-items: center;   width: var(--common-button-height);  place-content: center; margin-left: auto; margin-right: 25px;   font-size: 0px ; border:0px; outline:none; color: var(--color-white) ; width:30px; height:30px}
.Reactmodal-CloseBtn:before{font-family: "Material Design Icons";  text-indent: inherit;    content: "\F0156"; font-size: var(--font-size-18);      height: auto ; border:0px; outline:none; display: none;}
.Reactmodal-CloseBtn:hover,
.Reactmodal-CloseBtn:focus{background: var(--btn-common-bg-hvr); border-color: var(--btn-common-brdr-hvr); color:var(--btn-common-color-hvr) !important}


.ReactModal-Content-Container{ margin: auto; max-width: 100%;  background: #fff;}
.ReactModal-middle-container{   overflow: auto; padding: 20px; max-height: 500px;}
.ReactModal-Footer{   overflow: auto;}

.signin-modal-page{min-width: 480px;}
.modal-txt h3{display: flex; flex-direction: column; font-size: var(--font-size-16); font-weight: var(--font-weight-regular);     width: 250px;  margin: auto;    text-transform: capitalize; line-height: 25px;}
.modal-txt h3 span{margin:30px 0 20px;font-size: var(--font-size-20); font-weight: var(--font-weight-medium); line-height: normal;}



.ReactModal__Overlay--after-open{z-index: 9999;}
.profile-action-area{/*height:80px*/}
.profile-action-area .btn-primary{grid-gap:10px; margin:20px auto !important; display: flex; background: var(--theme-secondary-color); min-width: 160px; height: 50px; font-size: var(--font-size-20) !important;}
.profile-action-area .btn-primary i{display: none;}

.block-modal-page{min-width: 480px;}
.block-modal-page .modal-txt h3{width: 450px;}
.block-modal-page .modal-txt h3 span { font-size: 16px;}
#blockedPopup .ReactModal-Header h1 { font-size: 22px;}